import React from 'react';
import { graphql } from 'gatsby';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Grid,
  Box,
} from '@chakra-ui/react';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import Section from '../components/Section/';
import { Badges } from '../components/Badges/';
import { Wrapper, Title, SubTitle, Descriptor, Chart } from '../styles/agb';

const TOC = ({ data: { Postbote } }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PageLayout>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pt="6">
            <Tabs variant="line" variantColor="brand">
              <TabList>
                <Tab>Konditionen & Vergütungen</Tab>
                <Tab>Gebühren</Tab>
              </TabList>

              <TabPanels>
                <TabPanel pt="4">
                  <Text fontWeight="bold" fontFamily="Ubuntu" fontSize="2xl" mb="4">
                    DropPoint Vergütungen
                  </Text>
                  <Grid gridTemplateColumns="1fr auto" fontSize="sm" mb="8">
                    <Box fontWeight="bold">00 - 14 positive Bewertungen</Box>
                    <Box textAlign="right">0,50 € pro Sendung</Box>
                    <Box fontWeight="bold">15 - 49 positive Bewertungen</Box>
                    <Box textAlign="right">0,60 € pro Sendung</Box>
                    <Box fontWeight="bold">50 - 79 positive Bewertungen</Box>
                    <Box textAlign="right">0,70 € pro Sendung</Box>
                    <Box fontWeight="bold">80 - 99 positive Bewertungen</Box>
                    <Box textAlign="right">0,80 € pro Sendung</Box>
                    <Box fontWeight="bold">100 - 149 positive Bewertungen</Box>
                    <Box textAlign="right">0,90 € pro Sendung</Box>
                    <Box fontWeight="bold">ab 150 positive Bewertungen</Box>
                    <Box textAlign="right">1,00 € pro Sendung</Box>
                  </Grid>
                  <Text fontWeight="bold" fontFamily="Ubuntu" fontSize="2xl">
                    Paketaufbewahrungsvergütung
                  </Text>
                  <Text fontFamily="Ubuntu" fontSize="xxl" mb="4">
                    {`(>24h Aufbewahrung von Paketen)`}
                  </Text>
                  <Grid gridTemplateColumns="1fr auto" fontSize="sm">
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 48 Stunden:</Box>
                    <Box textAlign="right">0,50 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 72 Stunden:</Box>
                    <Box textAlign="right">0,50 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 96 Stunden:</Box>
                    <Box textAlign="right">1,50 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 120 Stunden:</Box>
                    <Box textAlign="right">1,50 € pro Paket</Box>
                  </Grid>
                  <Text fontSize="xs" mt="4">
                    Hiervon ausgenommen sind Annahmestellen, die via den SaaS/API Diensten von
                    DropFriends registriert wurden (siehe §13).
                  </Text>
                </TabPanel>
                <TabPanel pt="4">
                  <Text fontWeight="bold" fontFamily="Ubuntu" fontSize="2xl" mb="4">
                    Gebühren & Konditionen
                  </Text>
                  <Grid gridTemplateColumns="1fr auto" fontSize="sm" mb="8">
                    <Box fontWeight="bold">Widerruf von Kreditkartenzahlungen</Box>
                    <Box textAlign="right">15,00 €</Box>
                    <Box fontWeight="bold">Mahngebühren:</Box>
                    <Box textAlign="right">2,50 €</Box>
                  </Grid>
                  <Text fontWeight="bold" fontFamily="Ubuntu" fontSize="2xl">
                    Paketaufbewahrungskonditionen
                  </Text>
                  <Text fontFamily="Ubuntu" fontSize="xxl" mb="4">
                    Für die in Anspruch genommene Kapazität des DropPoints wird je eine
                    24-Stunden-Pauschale erhoben.
                  </Text>
                  <Grid gridTemplateColumns="1fr auto" fontSize="sm">
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 48 Stunden:</Box>
                    <Box textAlign="right">1,00 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 72 Stunden:</Box>
                    <Box textAlign="right">1,00 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 96 Stunden:</Box>
                    <Box textAlign="right">3,00 € pro Paket</Box>
                    <Box fontWeight="bold">Paketaufbewahrungszeit von 120 Stunden:</Box>
                    <Box textAlign="right">3,00 € pro Paket</Box>
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" size="sm" onClick={onClose}>
              Schließen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <SEO title="Nutzungsbedingungen" />
      <Hero image={Postbote} title="Allgemeine Geschäfts&shy;bedingungen">
        <div className="font-display font-xl tracking-wide">
          Jetzt die DropFriends-App downloaden.
        </div>
        <Badges />
      </Hero>
      <Section className="py-16">
        <Wrapper>
          <p className="mb-4">
            DropFriends (Maybachstr 22, 50670; Email: info -ät- dropfriends.de) ist Betreiber der
            DropFriends Website www.dropfriends.com, sowie der App „DropFriends“ (nachfolgend
            Plattform genannt).
          </p>
          <Title>§1 Nutzung</Title>
          <SubTitle>1.1. Registrierung durch Nutzer</SubTitle>
          <p className="mb-4">
            Die Nutzung der Plattform durch den Besteller und die Annahmestelle setzt die vorherige
            Registrierung voraus. Durch die Registrierung erklärt sich der Besteller und der
            Empfänger mit diesen AGB einverstanden. Die Registrierung gilt als abgeschlossen, wenn
            den Anweisungen in der im Anschluss an die Registrierung versendete
            Bestätigungs-/Willlkommensmail Folge geleistet wird. Erst danach gilt der
            Nutzungsvertrag mit dem als Empfänger oder Besteller registrierten Nutzer.
          </p>
          <p className="mb-4">
            Nutzer kann nur sein, wer als natürliche Person unbeschränkt geschäftsfähig ist. Der
            Kunde muss wahrheitsgemäße und vollständige Angaben zu seiner Person zur Verfügung
            stellen.
          </p>
          <p className="mb-4">
            Da die Identifizierung von Nutzern im Internet nur eingeschränkt möglich ist, können wir
            die Identität von Mitgliedern nicht garantieren. Unbeschadet dessen sind wir, sofern und
            soweit dies rechtlich zulässig ist, aus Gründen der Transparenz und Betrugsprävention
            berechtigt, jedoch nicht verpflichtet, von Mitgliedern behördliche Ausweisdokumente oder
            sonstige Informationen anzufordern oder zusätzliche Überprüfungen durchzuführen, um die
            Identität und den Hintergrund von Mitgliedern zu prüfen, Mitglieder mithilfe externer
            Datenbanken oder sonstiger Quellen zu überprüfen. Dies gilt auch für den Bereich der
            internationalen Geldwäschegesetze.
          </p>
          <p className="mb-4">
            Der Nutzer muss an der angegebenen Adresse wohnhaft oder falls es sich bei der
            angegebenen Adresse, um eine Gewerbeimmobilie handelt, die Berechtigung des
            Gewerbetreibenden haben, diese Adresse als Bestell- oder Annahmeadresse anzugeben.
          </p>
          <p className="mb-4">
            Die Registrierung ist nicht auf Dritte übertragbar. Der Dienst kann zum Zweck der
            Registrierung und nach erfolgter Anmeldung nicht anonym genutzt werden. Die Daten des
            Nutzers sind während der Dauer des Nutzungsvertrags stets aktuell zu halten. Der Nutzer
            ist zur Geheimhaltung der Kombination aus Nutzernamen und Passworts verpflichtet. Einen
            Missbrauch oder den Verlust dieser Informationen oder einen entsprechenden Verdacht muss
            der Kunde DropFriends unverzüglich mitteilen.
          </p>
          <SubTitle>1.2. Nutzungsvertrag</SubTitle>
          <p className="mb-4">
            Der Nutzungsvertrag mit dem Nutzer stellt gleichzeitig einen Rahmenvermittlungsvertrag
            dar. Dieser Rahmenvermittlungsvertrag räumt dem Besteller die Möglichkeit ein mit dem
            jeweiligen Empfänger Kontakt aufzunehmen und einen Vertrag über die angebotene
            Dienstleistung, vermittelt über DropFriends, zu schließen.
          </p>
          <p className="mb-4">
            Der durch DropFriends vermittelte Vertrag, über die Annahme des Zustellobjekts, kommt
            lediglich zwischen dem Besteller und dem Empfänger zustande. DropFriends agiert als
            Vermittler, sodass Handlungen oder Versäumnisse des Nutzers nicht auf DropFriends zurück
            zu führen sind. DropFriends kann dem Nutzer E-Mails und Nachrichten zusenden, die sich
            auf Aktionen und Transaktionen innerhalb des DropFriends Kontos, allgemeine
            Informationen zu DropFriends, den Partnern und damit verbundene Newsletter beziehen.
            Dies ist erforderlich, damit DropFriends sicherstellen kann, dass der Nutzer alle
            notwendigen Benachrichtigungen erhält, die im Zusammenhang mit der Nutzung von
            DropFriends stehen.
          </p>
          <SubTitle>1.3 Änderung der AGB</SubTitle>
          <p className="mb-4">
            DropFriends behält sich Änderungen dieser AGB vor, insbesondere um sie einer Änderung
            der Rechtslage anzupassen. Der Nutzer wird jeweils über die geänderten Bedingungen
            informiert. Für das jeweilige Vertragsverhältnis werden die geänderten Bedingungen erst
            wirksam nach Ablauf einer Frist von vier Wochen nach Empfang der Information, wenn der
            Nutzer der Änderung innerhalb dieser Frist nicht widersprochen hat. Im Fall des
            Widerspruchs kann DropFriends das Vertragsverhältnis mit sofortiger Wirkung kündigen.
            DropFriends wird in der Information gesondert auf die Widerspruchsfrist und die
            Kündigungsmöglichkeit hinweisen. Die Bestellung eines Nutzers unterliegt den zu dem
            Zeitpunkt der Bestellung jeweils gültigen AGBs.
          </p>
          <Title>§2 Dienstleistung</Title>
          <p className="mb-4">
            DropFriends ist eine Plattform, die registrierte Besteller und Empfänger zusammenbringt.
            DropFriends sorgt dafür, dass die bestellten Waren nicht ungefragt bei unzähligen
            verschiedenen Nachbarn auf den Besteller warten, sondern diese zu einem selbst
            ausgesuchten „DropFriend“ geliefert werden können. DropFriends ermöglicht das Aussuchen
            einer bestimmten Annahmestelle und die Interaktion mit dieser.
          </p>
          <SubTitle>2.1 Annahmestelle</SubTitle>
          <p className="mb-4">
            DropFriends ermöglicht es das Annahmestellen eine Vergütung (siehe § 4.3 ) für die
            Annahme, Aufbewahrung und Übergabe von Sendungen erhalten. Die Annahmestelle erhält eine
            entsprechende Gutschrift pro Sendung. Diese richtet sich nach der Bewertung (Regularien
            zum Bewertungssystem siehe § 10 ) und der Leistungsbereitschaft des Empfängers. Hiervon
            ausgenommen sind Annahmestellen, die via den SaaS/API Diensten von DropFriends
            registriert wurden (siehe §13).
          </p>
          <p>
            DropFriends übernimmt keine Haftung zur Einhaltung der von der Annahmestelle
            vordefinierten Sendungskriterien (Gewicht und Größe), Identifikationsmerkmalen oder
            Empfangszeiten, sondern stellt lediglich sicher, dass die technischen Voraussetzungen in
            der App gegeben sind.
          </p>
          <p className="mb-4">
            Die Annahmestelle kann selber auswählen, welche Identifikationstiefe genutzt werden
            soll, um den Besteller zweifelsfrei zu identifizieren. Hierbei gilt, dass mindestens der
            von DropFriends generierte (QR-)Code und/oder ein instant aufgenommenes Foto vom
            Besteller vor Bestellung genutzt werden müssen. Gegebenenfalls kann ein Datenabgleich
            über einen gültigen Lichtbildausweis vorgenommen werden. Auch ein Abgleich via
            Unterschrift ist möglich.
          </p>
          <p className="mb-4">
            Es besteht kein arbeitsrechtliches Verhältnis zwischen DropFriends und den
            Annahmestellen. Jegliche Auszahlungen müssen von den Annahmestellen steuerlich
            aufgefasst werden. DropFriends übernimmt keine Haftung bei missbräuchlichen Angaben der
            Annahmestellen gegenüber Behörden oder Arbeitgebern.
          </p>
          <Descriptor>2.1.1 Ruhende Auszahlungen bei Inaktivität</Descriptor>
          <p className="mb-4">
            Wir behalten uns das Recht vor, Annahmestellen mit einer Gebühr für ruhende Auszahlungen
            nach 2 Monaten Inaktivität lt. Gebührentabelle zu belasten, wenn das
            Annahmestellen-Konto nicht aktiv für Auszahlungen genutzt wird (d.h. das Auszahlungen
            „ruhen“). Die Gebühr für ruhende Auszahlungen fällt an,
          </p>
          <p className="mb-4">
            (a) wenn das DropFriends-Konto eröffnet ist, eine registrierte Annahmestelle (auch
            DropPoint genannt) aufweist und ein Guthaben zur Auszahlung aufweist, aber für 2 Monate
            nicht ausgezahlt werden konnte (also ruhend war), und
          </p>
          <p className="mb-4">
            (b) wenn Ihr Konto aufgelöst und geschlossen wurde und somit ruht, aber 2 Monate nach
            Schließung des Kontos noch immer ein Guthaben aufweist.
          </p>

          <Descriptor>2.1.2 Kontenführung, Verwaltung und Guthabenssicherung</Descriptor>
          <p className="mb-4">
            Die Gebühr für ruhende Auszahlungen umfasst einen Betrag für unsere Kontenführung,
            Verwaltung und Sicherung des Annahmestellen-Guthabens und wird monatlich vom
            entsprechenden DropFriends-Konto eingezogen, bis das Guthaben von der Annahmestelle
            abgezogen wird. Die Anwendung dieser Gebühr führt zur Verringerung des
            Annahmestellen-Guthabens, eventuell bis zum Nullsaldo. Zur Vermeidung der Gebühr für
            ruhende Auszahlungen sollten unbedingt alle notwendigen Maßnahmen ergriffen werden, um
            eine Auszahlung eines verbleibenden Guthabens vom Annahmestellen-Konto zu ermöglichen,
            bevor es für 2 oder mehr Monate ruht (vor oder nach Kündigung des Kontos):
          </p>
          <p className="mb-4">
            (a) Sobald ein zweiter Auszahlungsversuch fehlschlägt (z.B. wegen falscher Angabe einer
            IBAN oder fehlender IBAN), kommt eine Gebühr lt. Gebührentabelle zum Tragen. Das
            bestehende Guthaben wird autom. monatlich um die Höhe der Gebühr reduziert und autom.
            zum DropFriends-Unternehmensguthaben transferiert.
          </p>
          <p className="mb-4">
            (b) Ist das Guthaben der Annahmestelle kleiner der Gebühr lt. Gebührentabelle, dann wird
            das Guthaben auf 0 € gesetzt und komplett vereinnahmt. Eine Ausgleichung der Differenz
            wird nicht vom Kunden verlangt. Es wird nur das Guthaben der Annahmestelle vereinnahmt.
          </p>
          <SubTitle>2.2 Besteller</SubTitle>
          <p className="mb-4">
            Der Besteller kann über DropFriends eine Annahmestelle zum Empfang von Sendungen buchen.
            Eine Sendung darf maximal aus 3 Paketen bestehen. So kann die Annahmestelle selber
            auswählen, welche Identifikationsmerkmale genutzt werden sollen, um den Besteller
            zweifelsfrei zu identifizieren. Hierbei gilt, dass mindestens der von DropFriends
            generierte (QR-)Code und/oder ein instant aufgenommenes Foto vom Besteller vor
            Bestellung genutzt werden müssen. Gegebenenfalls kann ein Datenabgleich über einen
            gültigen Lichtbildausweis vorgenommen werden. Auch ein Abgleich via Unterschrift ist
            möglich. Der Besteller ist verpflichtet den vordefinierten Identifikationsprozessen der
            Annahmestelle zu folgen. Ansonsten darf die Übergabe der jeweiligen Sendung durch die
            Annahmestelle verweigert werden.
          </p>
          <SubTitle>2.3 Daten der Nutzer</SubTitle>
          <p className="mb-4">
            Verantwortung für die Bereitstellung aktueller Informationen bezüglich der
            Identifizierung und Abholzeiten, liegt beim jeweiligen Nutzer und liegt nicht im
            Verantwortungsbereich von DropFriends.
          </p>
          <Title>§3. Bestellung der Dienstleistung</Title>
          <p className="mb-4">
            Die auf der Plattform bereitgestellten Dienstleistungen stellen kein verbindliches
            Angebot zum Abschluss eines Vertrages von DropFriends und dem Besteller dar. Der
            Besteller kann zwischen verschiedenen Annahmestellen wählen, sofern die Buchungsoptionen
            miteinander korrespondieren. Anschließend kann der Besteller die Zahlungsart zur Buchung
            einer Annahmestelle über die Plattform wählen. Über den Button „ZAHLUNGSPFLICHTIG
            BESTELLEN“ (oder ähnliche Bezeichnung) beauftragt er DropFriends verbindlich mit der
            Vermittlung eines Dienstvertrags über die ausgewählte Dienstleistung und gibt zugleich
            gegenüber dem jeweiligen Empfänger ein verbindliches Angebot auf Abschluss eines
            Vertrages über die ausgewählte Dienstleistung ab.
          </p>
          <p className="mb-4">
            Hat der Besteller mit dem Klicken auf „ZAHLUNGSPFLICHTIG BESTELLEN“ (oder ähnliche
            Bezeichnung) eine Bestellung aufgegeben, verarbeitet DropFriends die Bestellung.
            Änderungen können nicht mehr vorgenommen werden. Die Bestellung wird im Anschluss an die
            entsprechende Annahmestelle weitergeleitet. DropFriends benachrichtigt den Nutzer über
            den Erhalt und die Bearbeitung der Bestellung mit einer Buchungsbestätigung, die per
            Nachricht versendet wird. Der Nutzer kann aktuelle und vorherige Buchungen in seinem
            Benutzerkonto einsehen. Die Buchungsbestätigung stellt die Annahme des Angebots des
            Kunden auf Abschluss eines Vertrags dar. Der Vertrag zwischen Besteller und
            Annahmestelle kommt erst zustande, wenn der Nutzer die Buchungsbestätigung per Nachricht
            erhalten hat. Mit der Buchungsbestätigung nimmt DropFriends, im Namen des jeweiligen
            Sendungs-Empfängers, das Angebot des Bestellers auf Abschluss eines Vertrages an.
          </p>
          <p className="mb-4">
            Die Buchungsbestätigung enthält Informationen zur Annahmestelle (Name und Adresse),
            Datum der Buchung, Name des Bestellers, Preis der Buchung und Zahlungsmethode. Weitere
            Informationen können jederzeit im Nutzerkonto eingesehen werden.
          </p>
          <p className="mb-4">
            Der Vertragstext und die Bestelldaten werden unter Wahrung des Datenschutzes gespeichert
            und sind vom Nutzer abrufbar. Die für den Empfänger relevanten Bestelldaten sendet
            DropFriends zur Durchführung des Vertrages an den Empfänger.
          </p>
          <p className="mb-4">
            Eine Auflistung der teilnehmenden Empfänger ist auf der Plattform zu finden. Hat der
            Besteller der Plattform die Erlaubnis gegeben, Standortdienste zu verwenden, ermittelt
            die Plattform den Standort des Bestellers, um Empfänger in seiner Nähe anzeigen zu
            können. Eine Ermittlung potentieller Empfänger ist auch durch die manuelle Eingabe von
            Standortinformationen möglich. Erst bei erfolgreicher Buchung erkennt der Besteller den
            vollständigen Klarnamen, sowie die benötigten Adressinformationen des Empfängers.
          </p>
          <Title>§4 Bezahlung</Title>
          <p className="mb-4">
            Der Preis der Buchung lässt sich im Buchungsprozess erkennen und enthält die
            anzuwendende Mehrwertsteuer.
          </p>
          <p className="mb-4">
            Die Bezahlung der Annahmestelle richtet sich nach folgender{' '}
            <Chart onClick={onOpen} role="button">
              Tabelle
            </Chart>
            . Bitte beachten Sie die dynamischen Vergütungsstrukturen. Von einer Bezahlung
            ausgenommen sind Annahmestellen, die via den SaaS/API Diensten von DropFriends
            registriert wurden (siehe §13).
          </p>
          <SubTitle>4.1 Bezahlmöglichkeiten</SubTitle>
          <p className="mb-4">
            Der Nutzer hat die Möglichkeit mit mindestens einer Zahlungsmethode, die auf der
            Plattform zur Verfügung steht, die Dienstleistung zu buchen. Barzahlungen, sowie Zahlung
            per Scheck sind nicht möglich.
          </p>
          <h4 className="text-xl mb-4">4.1.1 Kreditkarten</h4>
          <p className="mb-4">
            Möchte der Nutzer eine Kreditkarte zur Bezahlung nutzen, muss dieser bei Aufgabe einer
            Bestellung seine Kartennummer, das Ablaufdatum und die Kreditkartenprüfnummer angeben.
            Eine Speicherung der Kreditkarteninformationen erfolgt nicht von DropFriends.
            DropFriends arbeitet mit Zahlungsdienstleistern zusammen.
          </p>
          <p className="mb-4">
            Zur Sicherstellung des reibungslosen Ablaufes der Bestellung sowie entsprechend der
            gültigen Gesetze (z.B. Geldwäschegesetz), sind die Zahlungsanbieter verpflichtet die
            Zahlungsinformationen aufzunehmen. Ohne die Erhebung der entsprechenden Daten ist es dem
            Kunden nicht möglich DropFriends zu nutzen.
          </p>
          <h4 className="text-xl mb-4">4.1.2 Paypal</h4>
          <p className="mb-4">
            Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder „Kauf auf
            Rechnung“ via PayPal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an
            die PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg
            (im Folgenden „PayPal“), weiter. PayPal behält sich für die Zahlungsarten Kreditkarte
            via PayPal, Lastschrift via PayPal oder „Kauf auf Rechnung“ via PayPal die Durchführung
            einer Bonitätsauskunft vor. Das Ergebnis der Bonitätsprüfung in Bezug auf die
            statistische Zahlungsausfallwahrscheinlichkeit verwendet PayPal zum Zwecke der
            Entscheidung über die Bereitstellung der jeweiligen Zahlungsart. Die Bonitätsauskunft
            kann Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit Score-Werte in das
            Ergebnis der Bonitätsauskunft einfließen, haben diese ihre Grundlage in einem
            wissenschaftlich anerkannten mathematisch-statistischen Verfahren. In die Berechnung der
            Score- Werte fließen unter anderem Anschriftendaten ein. Weitere datenschutzrechtliche
            Informationen können Sie den PayPal{' '}
            <a
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 underline">
              Datenschutzgrundsätzen
            </a>{' '}
            entnehmen:
          </p>
          <h4 className="text-xl mb-4">4.1.3. Zahlungsdienstleister (Payment-Service-Provider)</h4>
          <p className="mb-4">
            DropFriends nutzt Payment-Service Provider. Bitte beachten Sie, dass sobald Sie ein
            DropFriends Konto erstellen, die Plattform automatisch ein Konto bei dem entsprechenden
            Zahlungsdienstleister für Sie errichtet. Mit der Registrierung bei DropFriends erklären
            Sie sich damit einverstanden.
          </p>
          <p className="mb-4">
            Welche Zahlungsdienstleister von DropFriends verwendet werden, entnehmen Sie bitte der
            Datenschutzerklärung.
          </p>
          <p className="mb-4">
            Falls Sie kein Konto bei dem Zahlungsdienstleister wünschen, ist die Erstellung eines
            DropFriends-Kontos nicht möglich. Eine nachträgliche Kündigung des
            Zahlungsdienstleisterkontos führt automatisch zur sofortigen Kündigung des
            DropFriends-Kontos.
          </p>
          <p className="mb-4">
            Für nähere Informationen lesen Sie auch bitte die Datenschutzerklärung unter dem Punkt
            Zahlungsdienstleister.
          </p>
          <p className="mb-4">
            Um einen reibungslosen Ablauf aller vom Zahlungsdienstleister zu erfüllenden Prozesse
            gewährleisten zu können, kann es notwendig sein, dass Informationen und Dokumente zur
            Verifizierung Ihrer Person bzw. Ihres Unternehmens durch den Zahlungsdienstleister
            einzuholen sind.
          </p>
          <SubTitle>4.2. Zahlungsdurchführung</SubTitle>
          <p className="mb-4">
            Bei Aufgabe einer Bestellung wird die Bezahlung der Dienstleistung im Benutzerkonto des
            Bestellers, gemäß der gewählten Zahlungsmethode, vorgemerkt. Das vom Besteller
            angegebene Zahlungskonto wird bei erfolgter Bestellung in Höhe der Abrechnung der
            Dienstleistung belastet.
          </p>
          <p className="mb-4">
            DropFriends darf das Zahlungsmittelangebot für die Nutzer nach eigenem Gutdünken in
            angemessener Weise überarbeiten, verändern oder umstrukturieren. Die Plattform verwendet
            nur anerkannte und genehmigte Zahlungsmethoden. Alle Bezahlungen werden über eine
            verschlüsselte Verbindung abgewickelt.
          </p>
          <p className="mb-4">
            Wird das Zahlungsmittel des Nutzers bei dem Versuch eine Bestellung zu bezahlen
            abgelehnt, ist der Nutzer angehalten zu überprüfen ob die eingegebenen Informationen
            korrekt sind und ggf. zu korrigieren, um die Buchung erfolgreich abzuschließen.
          </p>
          <p className="mb-4">
            In manchen Fällen erhält DropFriends eine Fehlermeldung, wenn ein Zahlungsmittel eines
            Kunden abgelehnt wird. Die Fehlermeldung ermöglicht es die Ursache der Ablehnung zu
            ermitteln. Um dem Besteller die Behebung des Fehlers zu erleichtern, kann DropFriends
            dem Nutzer darüber aufklären, warum sein Zahlungsmittel abgelehnt wurde.
          </p>
          <p className="mb-4">
            Hat der Besteller den Fehler korrigiert und das Zahlungsmittel wird weiterhin abgelehnt,
            rät DropFriends dem Kunden dazu seinen Zahlungsdienstleister bzw. sein Finanzinstitut zu
            kontaktieren.
          </p>
          <p className="mb-4">
            Dennoch gelten weiterhin die jeweiligen Bestimmungen des vom Empfänger gewählten
            Zahlungsdienstleister.
          </p>
          <p className="mb-4">
            Auch die Annahmestelle muss ein entsprechendes Bankkonto hinterlegen, auf dem die
            Auszahlung seiner Vergütung angedacht wird. Eine Auszahlung der Empfangsprämie erfolgt
            monatlich, innerhalb der ersten 7 Tage des Folgemonats. Der Mindestbetrag für
            Auszahlungen per Banküberweisung liegt bei 2,00 €. Mit der Zustimmung zu den
            vorliegenden AGB nimmt der Kunde diese zur Kenntnis und ist damit einverstanden, dass
            DropFriends dem Kunden gegenüber bezüglich der oben genannten Punkte nicht
            verantwortlich oder haftbar ist. Hiervon ausgenommen sind Annahmestellen, die via den
            SaaS/API Diensten von DropFriends registriert wurden (siehe §13).
          </p>
          <SubTitle>4.3 Gebühren, Konditionen und Vergütungen von DropFriends</SubTitle>
          <p className="mb-4">
            Die Gebühren, Konditionen und Vergütungen von DropFriends entnehmen Sie bitte der
            nachfolgenden{' '}
            <Chart onClick={onOpen} role="button">
              Tabelle
            </Chart>
            .
          </p>
          <Title>§5 Annahme von Sendungen durch Annahmestelle</Title>
          <p className="mb-4">
            Wenn eine Sendung eine Annahmestelle erreicht, was durch einen DropFriends- Code als für
            ein über DropFriends vermitteltes Paket zu identifizieren ist, so muss die Annahmestelle
            die Sendung fotografieren, um den Zustand zu dokumentieren. Nach Bestätigung der
            Fotoaufnahme durch die Annahmestelle wird das Bild entsprechend durch die Plattform
            gespeichert. Zudem kann das Zustellungsobjekt von der Annahmestelle in der App mit
            wenigen Worten beschrieben werden. Versäumt die Annahmestelle eine klare Dokumentation
            des Zustellungsobjekts, so kann die Vergütung von DropFriends nicht ausgezahlt werden.
          </p>
          <p className="mb-4">
            Eine Sendung kann auch aus mehreren Paketen bestehen. War es für den Besteller nicht vor
            der Bestellung möglich zu wissen, dass der Shop die bestellte Sendung in mehreren
            Paketen versendet, so muss die Annahmestelle dennoch alle zu der Sendung befindlichen
            Pakete annehmen, auch wenn die angegebenen Kapazitäten der Annahmestelle dadurch leicht
            überschritten werden. Die Vergütung erfolgt immer pro Sendung.
          </p>
          <p className="mb-4">
            Die Annahmestelle kann nur Pakete annehmen, die den angegebenen Maßen entsprechen und
            keine Nachnahme enthalten. Auch persönliche Annahmen, die nur mithilfe einer
            Identifizierung (bspw. PostIDENT Verfahren u.ä.) durch den Besteller erfolgen können,
            sind von der Annahme durch den Empfänger ausgeschlossen.
          </p>
          <p className="mb-4">
            Auch müssen die Sendungen den zollrechtlichen Bestimmungen der BRD unterliegen. Eine
            Sendung, die beim Zoll abgeholt werden müsste, gilt als vom Besteller falsch aufgegeben.
            In diesem Fall muss der Besteller selber den Kontakt zur entsprechenden Zollbehörde
            suchen. Eine Erstattung der Bestellgebühr erfolgt in diesem Fall nicht.
          </p>
          <p className="mb-4">
            Es ist auch zu beachten, dass verderbliche oder vom Lagerungsstandard abweichende Waren
            (bspw. Waren, die in einem Kühlschrank, in einem Humidor oder unter Berücksichtigung
            Brandschutz- und Sicherheitstechnischer Auflagen gelagert werden müssten), illegale
            Waren, Weiße Ware, sowie Lebendware nicht über DropFriends bestellt werden dürfen. Bei
            Zuwiderhandlung ist mit einer Vertragsstrafe und einem Ausschluss zu rechnen.
          </p>
          <p className="mb-4">
            Die Annahmestelle muss in diesen Fällen die Annahme verweigern. Die entsprechende Retour
            liegt im Risikobereich des Bestellers, sodass keine Erstattung erfolgt. Nimmt die
            Annahmestelle das Paket dennoch an, weil die o.g. Ausschlusskriterien nicht für den
            Empfänger zu erkennen waren und es entstehen Verunreinigungen oder sonstigen Schäden bei
            der Annahmestelle, so muss der Besteller diese auf eigene Kosten beheben (in den
            Ursprungszustand vor Paketannahme versetzen).
          </p>
          <p className="mb-4">
            Falls ein Zustellungsobjekt nicht ordnungsgemäß bei der Annahmestelle angekommen ist, so
            ist der Besteller über den Kommunikationsservice von DropFriends unverzüglich über die
            Nichtzustellung zu informieren. Die Abholung in einer Filiale oder die Weitergabe des
            Pakets kann eine nicht ordnungsgemäße Zustellung darstellen, die zu einer Retoure des
            Pakets führen kann. Es ist möglich, dass die Annahmestelle das Paket von einer Filiale
            abholt. Dies ist jedoch an den individuellen Voreinstellungen zur Paketannahme des
            Empfängers gebunden und kann nicht garantiert werden.
          </p>
          <p className="mb-4">
            Eine Erstattung (Informationen über Erstattung und Kulanzverhalten von DropFriends siehe
            § Erstattung) kann in diesen Fällen unter folgenden Umständen erfolgen: die
            Annahmestelle war nachweislich in der Abholzeit am angegebenen Wohnort und der Zusteller
            hat nachweislich keinen korrekten Zustellversuch unternommen.
          </p>
          <Title>§6 Lagerung der bestellten Objekte</Title>
          <p className="mb-4">
            Die Annahmestelle garantiert, dass die über DropFriends bestellten Pakete in dafür
            geeigneten Räumlichkeiten gelagert werden. Hierbei ist besonders zu beachten, dass die
            Pakete dort keinen Umwelteinflüssen, wie Feuchtigkeit oder starker Hitze ausgesetzt
            sind.
          </p>
          <p className="mb-4">
            Die Annahmestelle verpflichtet sich Pakete, die zweifelsfrei über DropFriends an ihn
            geliefert werden, nicht zu öffnen. Bei Zuwiderhandlungen ist mit einer Vertragsstrafe
            iHv. 25.000,00 € zu rechnen. Die Annahmestelle fungiert lediglich als Annahmestelle und
            wird nicht zum Eigentümer des Pakets inklusive des Inhalts. Sollte die Annahmestelle
            dennoch widerrechtlich ein nicht für ihn bestimmtes Paket öffnen, so ist dies
            unverzüglich an DropFriends zu melden. DropFriends ist berechtigt, die Annahmestelle zu
            sperren oder das Konto komplett aufzulösen und die entsprechenden rechtlichen Maßnahmen
            zu ergreifen.
          </p>
          <Title>§7 Abholung des bestellten Objekts durch den Besteller</Title>
          <p className="mb-4">
            Die bestellten Objekte kann der Besteller an der angegebenen Abholadresse des Empfängers
            innerhalb des angegebenen Abholzeitraums abholen. Der Zeitraum für die Abholung legt der
            Empfänger fest. Eine Beschreibung der Annahmstelle ist auf der Plattform angegeben und
            außerdem in der Bestellbestätigung vermerkt. Erscheint der Empfänger vor Beginn oder
            nach Ablauf des angegebenen Abholzeitraums bei der Abholadresse nicht, wird er gebeten
            innerhalb der Abholzeiten erneut zu kommen, um den regulären Tagesablaufs der
            Annahmestelle nicht zu stören. Es sei darauf hingewiesen, dass die Annahmestelle
            außerhalb der Abholzeiten nicht an der angegebenen Adresse sein muss. Der Besteller wird
            ersucht, sich gegenüber der Annahmstelle respektvoll zu verhalten. Bei Zuwiderhandlung
            kann dem Besteller die Nutzung der Plattform untersagt werden.
          </p>
          <p className="mb-4">
            Falls Bestellungen nicht innerhalb von 24 Stunden nach Erhalt bei der Annahmestelle
            abgeholt werden, so erhebt DropFriends eine Paketaufbewahrungsgebühr (siehe § 4.3 ).
            Holt der Besteller seine Bestellung nicht innerhalb des angegebenen Abholzeitraums (24
            Stund nach Ankommen bei der Annahmstelle) ab, ist die Annahmestelle berechtigt, nach
            einer angemessenen Frist, die Produkte an DropFriends auf Kosten des Bestellers zu
            versenden. Die weitere Kommunikation erfolgt auf Seite von DropFriends. Die DropPoint
            Buchung muss dennoch vom Besteller bezahlt werden. Auch eine Auszahlung an die
            Annahmestelle wird gewährleistet, insofern die Annahmestelle nicht via den SaaS/API
            Diensten von DropFriends registriert wurde (siehe §13). Sollte der Besteller wiederholt
            seine Bestellung in den angegebenen Zeiträumen nicht abholen, so kann dies zu einer
            Sperrung oder Löschung des Bestellerkontos führen.
          </p>
          <p className="mb-4">
            Bei der Abholung des Pakets muss der Besteller sich gegenüber dem Empfänger,
            entsprechend der ausgewählten Identifizierungsmethode zweifelsfrei identifizieren. Erst
            im Anschluss wird das Paket dem Besteller ausgehändigt.
          </p>
          <p className="mb-4">
            Erscheint der Besteller innerhalb des angegebenen Abholzeitraums bei der Annahmstelle
            und ihm wird nicht spätestens 30 Minuten nach seinem Erscheinen beim Besteller seine
            Bestellung ausgehändigt, ist der Besteller berechtigt eine Beschwerde bei DropFriends
            einzureichen. Diese Beschwerde kann zu einer Sperrung des DropPoints führen und dazu,
            dass die Annahmestelle das Paket an DropFriends auf eigene Kosten zusendet. Sollte der
            Empfänger wiederholt nicht anwesend sein, so kann das zu einer vollständigen Löschung
            des Kontos der Annahmestelle führen. Auch behält sich DropFriends rechtliche Mittel vor.
          </p>
          <p className="mb-4">
            Sollte die Annahmestelle aus wichtigen Gründen verhindert sein, so muss dies mindestens
            24 Stunden vor Abholung an DropFriends gemeldet werden. Eine neue Abholzeit kann
            daraufhin vereinbart werden.
          </p>
          <p className="mb-4">
            Eine Herausgabe des Pakets seitens der Annahmestelle an einen Dritten ist nur durch die
            entsprechenden Vollmachtsangaben in der App, seitens des Bestellers, möglich. Auch hier
            müssen die jeweiligen Bestimmungen zur Identifizierung des Bestellers und der
            Annahmestelle beachtet werden.
          </p>
          <Title>§8 Widerrufsrecht</Title>
          <p className="mb-4">Der Kunde kann seine Bestellung gemäß geltendem Recht widerrufen.</p>
          <Title>§9 Stornierung</Title>
          <p className="mb-4">
            Der Besteller ist berechtigt seine Bestellung zu stornieren, sofern er noch keine
            Bestellung an den Empfänger aufgegeben hat, er also noch nichts bestellt hat, welches an
            den Empfänger geliefert werden soll. Der Besteller muss in diesem Fall die Stornierung
            per Email an service@dropfriends.com unter der Hinzunahme der Bestellnummer richten.
            Eine Auszahlung des Dienstleistungspreises erfolgt nicht, wird jedoch in Form eines
            Gutscheins auf die nächste Bestellung angerechnet. Nach der Bestellung an den Empfänger,
            ist der Besteller nicht mehr berechtigt, seine Bestellung zu stornieren. Ihm steht keine
            Erstattung des Kaufpreises zu.
          </p>
          <Title>§10 Kommunikation & Nutzerbewertungen</Title>
          <p className="mb-4">
            DropFriends ermutigt die Nutzer nach einer erfolgreichen Übergabe eine Bewertung über
            den jeweiligen Besteller oder Empfänger abzugeben. Diese Bewertungen sind für
            registrierte Nutzer sichtbar und sollen auf Pünktlichkeit, Erreichbarkeit, Höflichkeit
            und Sorgfalt des Bestellers oder Empfängers abzielen. Insbesondere, aber nicht
            ausschließlich, dürfen Bewertungen, die der Besteller und der Empfänger über die
            Plattform erstellen, nicht:
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>
              Rassistische, homophobe, diffamierende, anstößige oder beleidigende Aussagen
              enthalten;
            </li>
            <li>zu Gewalt oder Diskriminierung aufrufen;</li>
            <li>
              die Rechte des geistigen Eigentums einer anderen Person oder juristischen Person
              verletzen;
            </li>
            <li>
              eine gesetzliche Verpflichtung gegenüber einem Dritten (z.B. Geheimhaltungspflicht)
              verletzen;
            </li>
            <li>
              zu illegalen Aktivitäten aufrufen oder in die Privatsphäre einer anderen Person
              eingreifen;
            </li>
            <li>den Eindruck erwecken, dass die Aussagen von DropFriends stammen;</li>
            <li>
              verwendet werden um sich fälschlicherweise für eine andere Person auszugeben oder eine
              Zugehörigkeit zu einer anderen Person vorzutäuschen.
            </li>
          </ul>
          <p className="mb-4">
            Die Aufzählung der oben aufgeführten untersagten Inhalte erhebt keinen Anspruch auf
            Vollständigkeit. DropFriends behält sich das Recht vor, verpflichtet sich aber nicht
            dazu, sofern nicht gesetzlich vorgeschrieben, jegliche Bewertungen, die von Nutzern auf
            die Plattform gepostet, geladen oder übermittelt wurden zu entfernen oder anzupassen,
            falls DropFriends feststellt, dass die Bewertung eine oder mehrere der oben aufgeführten
            untersagten Inhalte enthält, anderweitig anstößig ist oder DropFriends oder Dritten
            Schaden zufügen oder einer Haftung jeglicher Art aussetzen könnte oder aus sonstigen
            Gründen nicht zu tolerieren ist. Die Bewertungen auf der Plattform dienen zum Einen als
            Grundlage für die Vergütungsschwellen und als Information anderer Nutzer. Die
            Bewertungen stammen nicht von DropFriends, sondern von Nutzern, die über die Plattform
            als Besteller und Empfänger agieren und spiegeln nur deren Meinungen wieder. Jegliche
            Aussagen, Ratschläge und Meinungen, die von den Nutzern auf der Plattform zur Verfügung
            gestellt werden stammen im Ursprung ausschließlich von ihnen.
          </p>
          <p className="mb-4">
            Dementsprechend und mit in vollem Umfang gesetzlicher Zulässigkeit übernimmt DropFriends
            keinerlei Verantwortung oder Haftung für jegliche Bewertungen jeglicher Personen,
            einschließlich jeglicher Fehler, Diffamierungen, Obszönitäten, Auslassungen oder
            Unwahrheiten, die ein Nutzer in seinen Bewertungen äußert.
          </p>
          <Title>§11 Laufzeit</Title>
          <p className="mb-4">
            Der Nutzungsvertrag zwischen DropFriends und dem Nutzer wird auf unbestimmte Zeit
            geschlossen. Er kann von beiden Seiten jederzeit und ohne Einhaltung einer Frist durch
            schriftliche Nachricht an den jeweiligen Vertragspartner gekündigt werden. Der Nutzer
            kann den Nutzungsvertrag auch durch Löschung seines Accounts kündigen. Das Recht zur
            außerordentlichen, fristlosen Kündigung wegen der Verletzung einer vertraglichen Pflicht
            bleibt für beide Vertragsparteien unberührt. Von der Beendigung des Nutzungsvertrags
            sind etwaige bereits abgeschlossene Bestellungen zwischen dem Kunden und einem Betrieb
            nicht betroffen.
          </p>
          <p className="mb-4">
            Hiervon ausgenommen sind Registrierungen, die via den SaaS/API Diensten von DropFriends
            vorgenommen wurden. Es gelten die Vertragsbestandteile unter §13.
          </p>
          <Title>§12 Abwicklung über die Plattform</Title>
          <p className="mb-4">
            Die Nutzer sind verpflichtet, sämtliche Leistungen aus der Buchung ausschließlich über
            die Plattform abzuwickeln. Vereinbarungen außerhalb der Plattform sind als Umgehung zu
            verstehen.
          </p>
          <SubTitle>12.1 Umgehung der Buch- und Zahlungsabläufe.</SubTitle>
          <p className="mb-4">
            Nutzern ist es untersagt, die Buchungs- und Zahlungsabläufe, insbesondere die
            entsprechende Nutzungsgebühr zu umgehen. Sollten Nutzer außerhalb der Plattform einen
            Vertrag über die Lieferung des Pakets zu der Annahmestelle und anschließende Abholung
            des Bestellers, schließen (Umgehung), so besteht gleichwohl der Anspruch von DropFriends
            auf Zahlung der üblichen Zahlungspauschale.
          </p>
          <p className="mb-4">
            Die Nutzer sind verpflichtet, die geschlossene Buchung, vollumfänglich gegenüber
            DropFriends offen zu legen, sodass DropFriends, die hierauf gestützte Buchung
            nachträglich erfassen und vornehmen kann. Sollte weder Besteller noch Annahmestelle
            nähere Auskünfte geben, so wie Nutzungsgebühr in jedem Fall eingezogen. Eine Auszahlung
            an die Annahmestelle kann dann nicht erfolgen.
          </p>
          <SubTitle>12.2 Maßnahmen durch DropFriends</SubTitle>
          <p className="mb-4">
            DropFriends behält sich für den Fall, dass ein Nutzer gesetzliche Vorschriften, Rechte
            Dritter oder die AGB verletzt oder wenn DropFriends ein sonstiges berechtigtes Interesse
            hat, neben dem Recht der fristlosen Kündigung vor
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>den Nutzer zu verwarnen</li>
            <li>Die Nutzung der Plattform für den Nutzer zu beschränken</li>
            <li>
              den Nutzer vorläufig oder endgültig von der Nutzung der Plattform vollständig
              auszuschließen (Sperrung)
            </li>
            <li>
              sonstige rechtliche Schritte (wie bspw. die Erstattung von Strafanzeigen) einzuleiten.
            </li>
          </ul>
          <p className="mb-4">
            Bei der Wahl einer Maßnahme berücksichtigt DropFriends die berechtigten Interessen des
            betroffenen Nutzers sowie alle Umstände des Verstoßes, insbesondere den Grad des
            Verschuldens.
          </p>
          <SubTitle>12.3 Verbot der Neuregistrierung nach Ausschluss</SubTitle>
          <p className="mb-4">
            Nachdem ein Nutzer durch DropFriends gekündigt wurde, darf dieser Nutzer sich ohne
            ausdrückliche Genehmigung durch DropFriends auch mit einem anderen Nutzerkonto nicht
            anmelden oder über ein anderes Nutzerkonto die Leistungen nutzen und sich nicht neu
            registrieren.
          </p>
          <SubTitle>12.4 Auswirkungen auf bestehende Verträge</SubTitle>
          <p className="mb-4">
            Bereits abgeschlossene Bestellungen des Nutzers bleiben von einer Löschung eines
            Angebotes unberührt. Entsprechendes gilt für bereits fällige Ansprüche auf Zahlung der
            Bestellgebühr bzw. Auszahlung der Vergütung. Wird eine Bestellungsofferte vor Annahme
            von DropFriends gelöscht, kommt keine wirksame Bestellung zustande.
          </p>
          <Title>§13 Die DropFriends SaaS/API Lösungen</Title>
          <p className="mb-4">
            Es gelten die Allgemeinen Geschäftsbedingungen der DropFriends GmbH.
          </p>
          <p className="mb-4">
            Das Unternehmen gewährt Ihnen eine nicht ausschließliche, nicht übertragbare,
            widerrufliche und eingeschränkte Lizenz zur Nutzung des SaaS/API Dienstes und der
            zugehörigen Software sowie zur Anzeige der Ergebnisse des Dienstes. Sie erklären sich
            damit einverstanden, den Inhalt des Dienstes nicht zu kopieren oder zu verbreiten, es
            sei denn, dies ist in diesen Bedingungen ausdrücklich erlaubt. Sie erklären sich
            außerdem damit einverstanden, dass Sie nicht berechtigt sind, auf den Quell- oder
            Objektcode des Unternehmens zuzugreifen, diesen einzusehen oder zu verändern.
          </p>
          <p className="mb-4">
            Der DropFriends-SaaS/API-Service erfordert, dass alle Personen mindestens 18 Jahre alt
            und gewerbetreibend sind bevor sie ein Konto erstellen. Die Erstellung eines Kontos
            unter Verwendung falscher Informationen stellt einen Verstoß gegen unsere Bedingungen
            dar.
          </p>
          <p className="mb-4">
            Das Unternehmen gewährt Ihnen eine begrenzte, widerrufliche Lizenz die mit allen
            geltenden Gesetzen und diesen Nutzungsbedingungen übereinstimmt.
          </p>
          <p className="mb-4">
            Sie akzeptieren, dass Ihre Nutzung von DropFriends dazu führt, dass Sie ggf. Werbung von
            DropFriends oder Drittparteien sehen.
          </p>
          <p className="mb-4">
            Schließlich behält sich das Unternehmen das Recht vor, den Dienst einzustellen oder den
            Inhalt des Dienstes in irgendeiner Weise und zu jeder Zeit, mit oder ohne
            Benachrichtigung an Sie, ohne Haftung zu ändern.
          </p>
          <SubTitle>13.1 Nutzung der SaaS/API-Lösung</SubTitle>
          <p className="mb-4">Sie erklären und stimmen zu, dass:</p>
          <p className="mb-4">
            Sie in der Lage sind, diesen Vertrag zu schließen. Sie das Recht, die Befugnis und die
            Fähigkeit haben, die durch diese Bedingungen dargestellte Vereinbarung einzugehen und
            sich an alle Bestimmungen und Voraussetzungen dieser Bedingungen zu halten.
          </p>
          <p className="mb-4">
            Wenn Sie diese Bedingungen akzeptieren und die Dienste im Namen eines Unternehmens,
            einer Organisation, einer Institution oder einer anderen juristischen Person nutzen,
            sichern Sie zu, dass Sie dazu berechtigt sind und die Befugnis haben, diese Person an
            diese Bedingungen zu binden; in diesem Fall beziehen sich die Wörter "Sie" und "Ihr",
            wie sie in diesen Bedingungen verwendet werden, auf diese Person.
          </p>
          <p className="mb-4">
            Sie sammeln oder sammeln keine persönlichen Daten von Nutzern der Website oder des
            Dienstes sofern nicht ausdrücklich gestattet. Zudem werden Sie ohne die ausdrückliche
            vorherige schriftliche Genehmigung des Unternehmens kein Material des Dienstes kopieren,
            weiterverteilen, veröffentlichen oder anderweitig verwerten.
          </p>
          <p className="mb-4">
            Sie erklären sich damit einverstanden, alle Lizenzgebühren, Honorare und sonstigen
            Beträge in Bezug auf die Dienste und deren Pakete zu zahlen.
          </p>
          <p className="mb-4">
            Sie erklären sich damit einverstanden, dass die Überwachung von Prozessen oder Nutzungs-
            /Nutzerabläufen innerhalb des bereigestellten Dienstes des Unternehmens nicht zwingend
            durchgeführt wird.
          </p>

          <SubTitle>13.2 SaaS/API Konto Informationen</SubTitle>
          <p className="mb-4">
            Sie müssen sicherstellen, dass Ihre Kontoinformationen (die Informationen, die Sie bei
            der Registrierung für DropFriends angegeben haben) aktuell, vollständig, korrekt und
            wahrheitsgemäß sind. Alle DropFriends-Konten sind nicht übertragbar, und alle Rechte an
            ihnen erlöschen mit dem Tod des Kontoinhabers. Sie können Ihre Kontoinformationen
            jederzeit ändern oder korrigieren, indem Sie sich bei Ihrem DropFriends-Konto anmelden
            oder erhalten ggf. Unterstützung durch den DropFriends-Service, welchen Sie kontaktieren
            können.
          </p>
          <p className="mb-4">
            Indem Sie sich bei DropFriends über soziale Medien anmelden, erklären Sie sich damit
            einverstanden, dass wir Ihre Informationen erhalten und dementsprechend nutzen, wie es
            die gewählten sozialen Medien erlauben, und Ihre Anmeldedaten speichern. Die Erfassung
            und Verarbeitung Ihrer persönlichen Daten erfolgt in Übereinstimmung mit unserer
            Datenschutzrichtlinie, die Sie hier finden können.
          </p>
          <p className="mb-4">
            Sie sind für alle Aktivitäten verantwortlich, die auf oder über Ihr Konto erfolgen. Um
            Ihr Konto zu schützen, bewahren Sie Ihr Passwort vertraulich auf. Verwenden Sie Ihr
            Kontopasswort nicht bei anderen Diensten wieder.
          </p>
          <p className="mb-4">
            Wenn Sie bemerken, dass Ihr Konto kompromittiert wurde, sollten Sie DropFriends sofort
            benachrichtigen. Unbeschadet Ihrer gesetzlichen Rechte nehmen Sie zur Kenntnis und
            erklären sich damit einverstanden, dass Ihr Konto für Sie unzugänglich sein kann und
            dass alle mit dem Konto verbundenen Daten möglicherweise nicht abrufbar sind, wenn Sie
            Ihr Passwort vergessen haben und Ihr Konto anderweitig nicht validieren können.
          </p>
          <SubTitle>13.3 Nutzerbeiträge und Verhalten</SubTitle>
          <p className="mb-4">
            Jegliches Material, das Sie öffentlich auf DropFriends veröffentlichen, ist für alle
            Nutzer der Plattform zugänglich. Darüber hinaus erkennen Sie an, dass jede nicht
            autorisierte kommerzielle Nutzung des Dienstes ausdrücklich verboten ist. Sie
            verpflichten sich, alle anwendbaren lokalen, staatlichen, nationalen und internationalen
            Gesetze und Vorschriften einzuhalten und sind allein verantwortlich für alle Handlungen
            oder Unterlassungen, die unter Ihren Login-Daten erfolgen.
          </p>
          <SubTitle>13.4 Mitglieder und Abonnements</SubTitle>
          <p className="mb-4">
            DropFriends-Abonnementgebühren sind in Euro angegeben (EUR). Die Abonnements laufen zu
            bestimmten Daten ab, die im Konto des jeweiligen Nutzers angezeigt werden. DropFriends
            kann nach eigenem Ermessen Gebühren für das Recht zur Nutzung der Paket-Versionen des
            Dienstes erheben oder Paket-Mitgliedschaften kostenlos vergeben. Sie erklären sich damit
            einverstanden, dass DropFriends das uneingeschränkte Recht hat, solche Abonnements nach
            eigenem Ermessen zu verwalten, zu regeln, zu kontrollieren, zu ändern und/oder zu
            streichen, und dass das Unternehmen Ihnen gegenüber keine Haftung aufgrund der Ausübung
            dieses Rechts übernimmt. Der Kaufpreis der Abonnements wird sich im Laufe der Zeit
            voraussichtlich ändern.
          </p>
          <SubTitle>13.5 Automatische Vertragsverlängerung</SubTitle>
          <p className="mb-4">
            Wir verwenden die automatische Verlängerung für alle unsere Abonnements. Nach Ablauf
            eines jeden Abonnements verlängern wir Ihr Abonnement automatisch und belasten das von
            Ihnen angegebene Zahlungsmittel, es sei denn, Sie kündigen Ihr Abonnement mindestens
            einen Monat vor Ablauf des aktuellen Zeitraums. Sofern in unseren Nutzungsbedingungen
            nichts anderes angegeben ist, wird Ihr Abonnement automatisch zum gleichen Preis
            verlängert, ausgenommen Werbe- und Rabattpreise.
          </p>
          <p className="mb-4">
            Sie erklären sich damit einverstanden und bestätigen, dass DropFriends eine Gebühr in
            Verbindung mit einem Verkauf oder einer Umtauschtransaktion erheben oder einen
            Verkaufs-, Kauf- oder Handelsauftrag einzeln oder im Hinblick auf allgemeine Volumen-
            oder Preisbeschränkungen, die vom Unternehmen aus beliebigen Gründen oder ohne Grund
            festgelegt wurden, ablehnen kann. Das Unternehmen kann auch jederzeit und ohne Angabe
            von Gründen bestimmte Funktionen ganz oder teilweise einstellen.
          </p>
          <SubTitle>13.6 Alle Verkäufe sind endgültig</SubTitle>
          <p className="mb-4">
            Sie erklären sich damit einverstanden, dass alle Verkäufe von virtuellen Produkten und
            Zugängen durch das Unternehmen endgültig sind. Es werden keine Rückerstattungen gewährt,
            außer im alleinigen und absoluten Ermessen von DropFriends. Alle virtuellen Produkte
            verfallen, wenn Ihr Konto aus irgendeinem Grund nach dem alleinigen und
            uneingeschränkten Ermessen des Unternehmens gekündigt oder gesperrt wird, oder wenn das
            Unternehmen die Bereitstellung des Dienstes einstellt.
          </p>
          <SubTitle>13.7 Rückerstattung für Abonnement-Gebühren</SubTitle>
          <p className="mb-4">
            DropFriends bietet im Allgemeinen keine Rückerstattungen für Abonnement-Gebühren an,
            außer in seltenen Fällen und im alleinigen und absoluten Ermessen des Unternehmens.
            Sobald eine Rückerstattung erfolgt ist, haben Sie keinen Zugang mehr zu den
            Paket-Optionen. Um eine Rückerstattung zu beantragen, wenden Sie sich bitte an unseren
            Kundendienst. Wir werden Ihren Antrag auf Rückerstattung prüfen und bearbeiten.
          </p>
          <SubTitle>13.8 Eingeschränkte Rechte</SubTitle>
          <p className="mb-4">
            Diese Bedingungen berühren nicht die gesetzlichen Rechte, die Ihnen als Verbraucher nach
            EU-Recht zustehen und die nicht vertraglich geändert oder aufgehoben werden können. Wenn
            Sie Ihren Wohnsitz in einem Land der Europäischen Union haben, berühren diese
            Bedingungen nicht Ihr Recht, sich auf zwingendes lokales Recht oder eine
            Gerichtsstandsklausel zu berufen, die nicht vertraglich geändert werden kann. Die
            Europäische Kommission stellt eine Plattform zur OnlineStreitbeilegung zur Verfügung,
            auf die Sie hier zugreifen können.
          </p>
          <p className="mb-4">
            Abgesehen von einer eingeschränkten, persönlichen, widerrufbaren, nicht übertragbaren
            und nicht unterlizenzierbaren Lizenz zur Nutzung des Paket-Dienstes haben Sie keine
            Rechte oder Ansprüche an virtuellen Produkten, die in dem Dienst erscheinen oder von ihm
            stammen, oder an anderen Attributen, die mit der Nutzung des Dienstes verbunden oder in
            dem Dienst gespeichert sind.
          </p>
          <p className="mb-4">
            Das Unternehmen hat das uneingeschränkte Recht, diese virtuellen Produkte nach eigenem
            Ermessen zu verwalten, zu regulieren, zu kontrollieren, zu modifizieren und/oder zu
            eliminieren, und das Unternehmen haftet Ihnen oder anderen gegenüber nicht für die
            Ausübung dieser Rechte.
          </p>
          <p className="mb-4">
            Sie bestätigen, dass das Unternehmen, wenn es der Meinung ist, dass irgendwelche
            Handlungen oder Produkte in Ihrem Konto in Verbindung mit einem Betrug oder anderen
            unlauteren Handlungen erhalten/durchgeführt wurden, oder wenn das Unternehmen aus
            irgendeinem Grund oder ohne Grund entscheidet, dass es im besten Interesse des
            Unternehmens ist, Artikel von Ihrem Konto löschen und das Konto auflösen kann.
          </p>
          <SubTitle>13.9 Fehler und Probleme</SubTitle>
          <p className="mb-4">
            Die unter den DropFriends-Netzwerkseiten lizenzierten Dienste werden von zuständigen
            Teams immer wieder getestet; dennoch kann es vorkommen, dass sie nicht korrekt
            funktionieren, funktionale, konzeptionelle und/oder dokumentarische Fehler und Probleme
            aufweisen. In diesen Fällen wird DropFriends versuchen, die gefundenen Probleme und
            Fehler zu beheben und den Käufer mit funktionierenden Updates zu versorgen.
          </p>
          <SubTitle>13.10 System- und Netzsicherheit</SubTitle>
          <p className="mb-4">
            Verstöße gegen die System- oder Netzsicherheit sind verboten und können straf- und
            zivilrechtliche Folgen haben. Wir untersuchen Vorfälle, die solche Verstöße beinhalten,
            und können die Strafverfolgungsbehörden einbeziehen und mit ihnen zusammenarbeiten, wenn
            ein krimineller Verstoß vermutet wird. Sie müssen angemessene Sicherheitsvorkehrungen im
            Hinblick auf Ihre Nutzung des Dienstes treffen. Sie tragen die alleinige Verantwortung
            für etwaige Sicherheitsverletzungen, die die von Ihnen kontrollierten Server betreffen.
          </p>
          <SubTitle>13.11 Links zu anderen Websites oder ähnliches</SubTitle>
          <p className="mb-4">
            DropFriends kann Links und Verweise auf andere Websites enthalten, die von Dritten
            unterhalten werden. Solche Links bedeuten nicht, dass wir diese Websites Dritter oder
            das darin enthaltene Material gutheißen. Natürlich haben wir keine Kontrolle über die
            Verfügbarkeit, die Genauigkeit, die Datenschutzrichtlinien oder die Aktualität solcher
            Websites Dritter oder über jegliche Informationen, Inhalte, Produkte oder
            Dienstleistungen, die von solchen Websites Dritter zugänglich sind, und sind daher nicht
            dafür verantwortlich. DropFriends übernimmt keine Haftung für den Inhalt oder die
            Nutzung des Inhalts, der über einen solchen Hyperlink zugänglich ist.
          </p>
          <SubTitle>13.12 Ausschluss von Garantien</SubTitle>
          <p className="mb-4">
            Die Informationen und Dienste auf DropFriends können technische Ungenauigkeiten oder
            typografische Fehler enthalten. Die hierin enthaltenen Informationen werden in
            regelmäßigen Abständen geändert.
          </p>
          <p className="mb-4">
            DropFriends gibt keine Zusicherungen oder Garantien in Bezug auf digitale Produkte,
            Dienstleistungen, Informationen, Materialien oder Grafiken auf der Website zur Verfügung
            gestellt werden. Alle ausdrücklichen und stillschweigenden Garantien, einschließlich,
            aber nicht beschränkt auf die Garantien der Marktgängigkeit, der Eignung für einen
            bestimmten Zweck und der Nichtverletzung von Eigentumsrechten, werden ausdrücklich und
            im vollen gesetzlich zulässigen Umfang abgelehnt. Das Unternehmen lehnt im vollen
            gesetzlich zulässigen Umfang jegliche Gewährleistung ab: (1) für die Sicherheit,
            Zuverlässigkeit, Aktualität, Genauigkeit und Leistung der Sites und des Dienstes; (2)
            für andere Dienstleistungen oder Produkte, die über die Sites des Unternehmens oder die
            Sites oder den Dienst empfangen oder beworben werden oder auf die über Links auf der
            Site zugegriffen wird; (3) für Viren oder andere schädliche Komponenten in Verbindung
            mit den Sites oder dem Dienst; oder (4) für von Benutzern hochgeladene diffamierende
            Inhalte.
          </p>
          <SubTitle>13.13 Schadensersatz</SubTitle>
          <p className="mb-4">
            Sie erklären sich damit einverstanden, uns, unsere leitenden Angestellten,
            Tochtergesellschaften, verbundenen Unternehmen, Rechtsnachfolger, Zessionare,
            Direktoren, leitenden Angestellten, Vertreter, Dienstleister, Lieferanten und
            Mitarbeiter zu schützen und schadlos zu halten von jeglichen Ansprüchen oder
            Forderungen, einschließlich angemessener Anwalts- und Gerichtskosten, die von Dritten
            aufgrund von Materialien erhoben werden, die Sie einreichen, veröffentlichen oder über
            den Dienst zur Verfügung stellen, Ihrer Nutzung des Dienstes, Ihrer Verletzung der
            Bedingungen, Ihrer Verletzung von Zusicherungen und Gewährleistungen in diesen
            Bedingungen, Wenn Sie die Dienste im Namen einer Firma, eines Unternehmens oder einer
            anderen juristischen Person nutzen oder wenn Sie den Dienst für kommerzielle Zwecke
            nutzen, halten Sie und die juristische Person das Unternehmen schadlos und stellen es
            von allen Klagen, Ansprüchen oder Aktionen frei, die sich aus der Nutzung des Dienstes
            oder der Verletzung dieser Bedingungen ergeben oder damit zusammenhängen, einschließlich
            jeglicher Haftung oder Ausgaben, die sich aus Ansprüchen (einschließlich Ansprüchen
            wegen Fahrlässigkeit), Verlusten, Schäden, Klagen, Urteilen, Prozesskosten und
            Anwaltsgebühren ergeben.
          </p>
          <SubTitle>13.14 Trennbarkeit</SubTitle>
          <p className="mb-4">
            Diese Bedingungen stellen zusammen mit den Datenschutzrichtlinien und den Allgemeinen
            Geschäftsbedingungen des Anbieters die gesamte Vereinbarung zwischen DropFriends und
            Ihnen in Bezug auf die Website und aller Dienste dar und ersetzen alle früheren und
            ähnlich gelagerten Mitteilungen.
          </p>
          <SubTitle>13.15 Begrenzung der Haftung</SubTitle>
          <p className="mb-4">
            Unter keinen Umständen haften wir für direkte, indirekte, zufällige, besondere, Folge-
            oder exemplarische Schäden (selbst wenn wir auf die Möglichkeit solcher Schäden
            hingewiesen wurden), die sich aus irgendeinem Aspekt Ihrer Nutzung der Sites oder des
            Dienstes ergeben, unabhängig davon, ob die Schäden aus der Nutzung oder dem Missbrauch
            der Site oder des Dienstes, aus der Unfähigkeit, die Sites oder den Dienst zu nutzen,
            oder aus der Unterbrechung, Aussetzung, Modifizierung, Änderung oder Beendigung der
            Sites oder des Dienstes resultieren. Diese Beschränkung gilt auch für Schäden, die
            aufgrund anderer Dienstleistungen oder Produkte entstehen, die über unsere Website oder
            den Dienst oder über Links auf der Website erhalten oder beworben werden, sowie aufgrund
            von Informationen oder Ratschlägen, die über die Websites oder den Dienst oder über
            Links auf den Websites erhalten oder beworben werden. Diese Beschränkungen gelten im
            größtmöglichen gesetzlich zulässigen Umfang. In einigen Gerichtsbarkeiten sind
            Haftungsbeschränkungen nicht zulässig und einige der vorstehenden Beschränkungen gelten
            möglicherweise nicht für Sie.
          </p>
          <SubTitle>13.16 Folgen von Verstößen gegen das Vertragswerk</SubTitle>
          <p className="mb-4">
            Wir sind berechtigt, ohne vorherige Ankündigung den Dienst zu sperren oder zu entfernen,
            wenn wir Sachverhalte feststellen, die uns zu der Annahme veranlassen, dass unser Dienst
            entgegen den vorliegenden Bedingungen genutzt wird. Sie erklären sich damit
            einverstanden, bei der angemessenen Untersuchung eines vermuteten Verstoßes gegen diese
            Bedingungen mit uns zusammenzuarbeiten. Wir werden versuchen, Sie vor Ihrer Sperrung zu
            kontaktieren; eine vorherige Benachrichtigung ist jedoch nicht garantiert.
          </p>
          <p className="mb-4">
            Sie bestätigen, dass Ihre Nutzung des Dienstes von der Einhaltung dieser Bedingungen
            abhängig ist und dass jede Nutzung des Dienstes, die gegen diese Bedingungen verstößt,
            nicht nur als Verstoß gegen diese Bedingungen, sondern auch als Verletzung der
            Urheberrechte des Unternehmens an dem Dienst betrachtet wird. Das Unternehmen behält
            sich das Recht vor, Ihren Zugang zum Dienst ohne Vorankündigung zu sperren, wenn Sie
            gegen diese Bedingungen verstoßen.
          </p>
          <p className="mb-4">
            Sie bestätigen, dass wir Ihr Konto aus jedem beliebigen Grund oder ohne jeglichen Grund
            löschen können, und wenn wir Ihr Konto wie in diesen Bedingungen vorgesehen löschen (z.
            B. aufgrund eines Verstoßes gegen unsere Urheberrechtsrichtlinien), verlieren Sie alle
            virtuellen Produkte auf Ihrem Konto, unabhängig davon, ob Ihnen diese Produkte
            unentgeltlich oder aufgrund einer Zahlung oder der Erbringung von Dienstleistungen zur
            Verfügung gestellt wurden, und in einem solchen Fall ist das Unternehmen nicht
            verpflichtet, Ihnen eine Rückerstattung zu gewähren.
          </p>
          <Title>§14 Zugriffsmöglichkeit</Title>
          <p className="mb-4">
            DropFriends ist darauf bedacht zu gewährleisten, dass auf die Plattform 24 Stunden am
            Tag zugegriffen werden kann. Jedoch garantiert DropFriends nicht, dass der Zugriff
            ununterbrochen möglich ist.
          </p>
          <Title>§15 Geistiges Eigentum und Urheberrecht</Title>
          <p className="mb-4">
            DropFriends ist Inhaber des Urheberrechts und aller anderen Schutzrechte an der Website
            und deren Inhalt, sowie (Web-) App, SaaS und API. Sämtliche Inhalte stehen
            ausschließlich DropFriends zu, es sei denn, die Inhaberschaft eines Dritten ist
            besonders gekennzeichnet. Der Nutzer erhält keinerlei Rechte oder Lizenzen zur Nutzung
            dieser.
          </p>
          <p className="mb-4">
            DropFriends ist Inhaber sämtlicher Schutzrechte an Software, die auf der Plattform zu
            finden ist. Die unberechtigte Nutzung, Vervielfältigung oder Weitergabe solcher Software
            ist ausdrücklich untersagt. Die Nutzung der Software kann einer besonderen
            Lizenzvereinbarung und deren Bedingungen unterliegen. Soweit eine solche Vereinbarung
            nicht besteht, überträgt der Inhaber der Software hiermit dem Nutzer ein persönliches,
            nicht übertragbares Nutzungsrecht, das sich ausschließlich auf die Nutzung der Software
            zum Zwecke des Zugriffs, der Darstellung und der vertragsgemäßen Nutzung der Website in
            Übereinstimmung mit diesen Allgemeinen Geschäftsbedingungen erstreckt. Jegliche
            Vervielfältigung der Software auf irgendeinen anderen Server oder Datenträger zum Zwecke
            weiterer Vervielfältigungen ist ausdrücklich verboten. Dies beinhaltet auch Hacking oder
            Scraping.
          </p>
          <p className="mb-4">
            Eine andere als die vertragliche Nutzung der Website und deren Inhalt ist nicht erlaubt,
            dies schließt auch die Reproduktion, Modifikation, Distribution, Übertragung,
            Publikation, Präsentation, und/oder die Zurschaustellung mit ein. Insbesondere ist die
            Verwendung von Logos und/oder Grafiken der DropFriends Website außerhalb dieser
            untersagt, sofern nicht die ausdrückliche Einwilligung von DropFriends und/oder der
            Anbieter dazu erteilt wurde.
          </p>
          <p className="mb-4">
            Der Nutzer darf die Digital- oder Papierkopien jeglichen Materials, die er aus der
            Plattform druckt, nicht verändern. Der Kunde darf ohne Begleittext keine Bilder, Fotos
            oder andere Grafiken, Video- oder Audio-Sequenzen verwenden. Der Nutzer ist angehalten
            sicherzustellen, dass DropFriends als Autor des Materials auf der Plattform jederzeit
            ausgewiesen und erkennbar ist.
          </p>
          <p className="mb-4">
            Der Nutzer darf weder Material von der Plattform noch die Plattform selbst für
            kommerzielle Zwecke nutzen ohne eine entsprechende Lizenz von DropFriends erhalten zu
            haben.
          </p>
          <Title>§16 Haftung</Title>
          <p className="mb-2">16.1 Schadenersatzansprüche</p>
          <p className="mb-6">
            Schadensersatzansprüche gegenüber DropFriends sind durch den Nutzer ausgeschlossen,
            sofern keine gesetzliche Regelung dies anders wertet. Der Haftungsausschluss gilt auch
            zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen von DropFriends.
          </p>
          <p className="mb-2">16.1.2. Pflichtverletzungen</p>
          <p className="mb-4">
            DropFriends haftet dem Nutzer gegenüber nicht für leicht fahrlässige
            Pflichtverletzungen, sofern daraus keine Schäden für den Leib und das Leben des Nutzers
            resultieren.
          </p>
          <p className="mb-4">
            DropFriends haftet insbesondere nicht für Störungen, Beeinträchtigungen, Beschränkungen
            oder sonstige Leistungshindernisse, die auf Umständen außerhalb des
            Verantwortungsbereiches von DropFriends beruhen.
          </p>
          <p className="mb-6">
            Ansonsten kann DropFriends nur haftbar gemacht werden, sofern die Verletzung einer
            Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
            erst möglich gemacht hätten und auf deren Einhaltung der Nutzer regelmäßig vertrauen
            darf. Dies ist auf den Ersatz des vorhersehbaren und typischen Schaden beschränkt.
          </p>
          <p className="mb-6">16.2. Ansonsten ist eine Haftung von DropFriends ausgeschlossen.</p>
          <p className="mb-6">
            15.3. DropFriends übernimmt keine Haftung für die Leistung und die Rechtmäßigkeit aus
            den zwischen dem Besteller und der Annahmestelle geschlossenen Verträge, da DropFriends
            weder Vertragspartner der Buchung, noch Annahmestelle und Besteller Erfüllungs-und/oder
            Verrichtungsgehilfe von DropFriends ist.
          </p>
          <p className="mb-2">16.4. Haftung für Drittanbieter</p>
          <p className="mb-6">
            Eine genaue Drittanbieterliste ist auf der Datenschutzerklärung einzusehen. DropFriends
            haftet nicht für die Leistungen, die ein Drittanbieter im Prozess des Vertragsschluss
            zwischen Besteller und Annahmestelle übernimmt, sofern es sich um keinen Erfüllungs-
            und/oder Verrichtungsgehilfen von DropFriends handelt.
          </p>
          <p className="mb-2">16.5. Schäden durch Code</p>
          <p className="mb-6">
            Dem Nutzer ist bekannt, dass die von DropFriends verwendete Software nach derzeitigem
            Stand der Technik nicht vollständig frei von Fehlern erstellbar ist. Unter gewissen
            Umständen ist es möglich, dass der von DropFreinds verwendete Code nicht von den
            technischen Geräten der Nutzer korrekt ausgelesen, interpretiert oder verarbeitet werden
            kann. DropFriends kann für eventuelle schadhafte Veränderungen an den Endgeräten nicht
            haftbar gemacht werden, auch wenn der verwendete Code ursächlich für die Veränderung
            ist.
          </p>
          <Title>§17 Freistellung von DropFriends durch Nutzer</Title>
          <p className="mb-4">
            Nutzer stellen DropFriends, auch deren Erfüllungsgehilfen, gesetztliche Vertreter und
            Rechtsnachfolger von sämtlichen Ansprüchen, Forderungen, Schadensersatzansprüche,
            Beschwerden und Klagen, die im Falle von Rechtsverletzungen durch sie auf erstes
            Anfordern von sämtlichen Ansprüchen anderer Nutzer und/oder Dritter in Bezug diese
            Rechtsverletzungen, frei. Dies schließt die angemessenen Kosten der Rechtsverteidigung
            ein. Diese Freistellung umfasst neben generellen Verletzungen von Rechten Dritter
            insbesondere
          </p>
          <p className="mb-4">
            - Rechtsverletzungen von Nutzern, die aus falschen Profilangaben hergeleitet werden -
            Rechtsverletzungen aus einer falschen Bestellung
            <br />
            - Rechtsverletzungen aufgrund von Bewertungen durch Kunden und <br />- Ansprüche aus
            Bestellungen, die gegen DropFriends geltend gemacht werden, wie insbesondere nicht
            erfolgte Zahlungen, Vertragsverletzungen der Nutzer und Schäden, die an Objekten oder
            Eigentum der Annahmestelle entstehen.
          </p>
          <Title>§18 Datenschutz</Title>
          <p className="mb-4">
            DropFriends erhebt, verwendet und speichert personenbezogene Daten des Kunden
            ausschließlich im Rahmen der gesetzlichen Vorgaben. Darüber hinaus werden Fotos der
            Nutzer zwecks Identifzierung gespeichert, sodass nicht bei jeder Bestellung ein neues
            Foto erstell werden muss.
          </p>
          <p className="mb-4">
            Eine Übermittlung der Daten kann an Drittanbieter erfolgen. Für genauere Informationen
            lesen Sie dazu die Datenschutzerklärung.
          </p>
          <Title>§19 Übertragung auf Dritte</Title>
          <p className="mb-4">
            DropFriends ist berechtigt seine Rechte und Pflichten aus dem jeweiligen
            Vertragsverhältnis auf Dritte zu übertragen.
          </p>
          <Title>§20 Geltendes Recht</Title>
          <p className="mb-8">
            Die vorliegenden AGBs und die Dienstleistungsverträge unterliegen den Gesetzen der
            Bundesrepublik Deutschland.
          </p>
          <p className="text-sm italic">Zuletzt aktualisiert: 26.September.2021</p>
        </Wrapper>
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default TOC;
